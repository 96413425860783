import {type ClassValue, clsx} from "clsx"
import {twMerge} from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const normalizeCountForm = (number, words_arr) => {
  number = Math.abs(number);
  if (Number.isInteger(number)) {
    let options = [2, 0, 1, 1, 1, 2];
    return words_arr[(number % 100 > 4 && number % 100 < 20) ? 2 : options[(number % 10 < 5) ? number % 10 : 5]];
  }
  return words_arr[1];
}

export const checkEqual = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

export const decode = (str: string): string => Buffer.from(str, 'base64').toString('binary');
export const encode = (str: string): string => Buffer.from(str, 'binary').toString('base64');

export const dateWithoutTimezone = (date: Date) => {
  const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.valueOf() - tzoffset)
      .toISOString()
      .slice(0, -1);
};